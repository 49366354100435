const themeModule = {
  namespaced: true,
  state: () => ({
    items: [],
    payment_method: null,
    type: null,
  }),
  mutations: {
    set(state, item) {
      state.items = item.items;
      state.payment_method = item.payment_method;
      state.type = item.type;
    },
    setItems(state, items) {
      state.items = items;
    },
  },
  actions: {
    setItems({ commit }, item) {
      commit("setItems", item);
    },
  },
  getters: {
    get: (state) => () => {
      return {
        items: state.items,
        payment_method: state.payment_method,
        type: state.type,
      };
    },
  },
};

export default themeModule;
