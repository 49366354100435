// import Vuex from "vuex";
import voucherModule from "@/modules/voucher-modules";
import restoCreateModule from "@/modules/restoCreate-modules";
import themeModule from "@/modules/theme-modules";
import cartModule from "@/modules/cart-modules";
import { createStore } from "vuex";
// export default new Vuex.Store({
//   state: {
//     //RestoFeature
//     pageData: null,
//     pageDataNumber: null,
//     pageDataLat: null,
//     pageDataLong: null,

//     //RestoSearch
//     q: null,
//     prov_: null,
//     city_: null,
//     type_: null,
//     cui_: null,
//     menu: null,
//     resto: null,

//     //Resto (User)
//     pageRestoCategoryOptions: null,
//     pageRestoBannerOptions: null,
//     pageRestoTransaction: null,
//     legitimateIosDevice: null,
//     permissionLocation: null,
//     latitude: null,
//     longitude: null,
//     device_id: null,

//     //History (User)
//     historyData: null,
//     // idHistoryData: null,
//     historyPreviousRouteData: null,
//   },
//   mutations: {
//     setPageData(
//       state,
//       { pageData, pageDataNumber, pageDataLat, pageDataLong }
//     ) {
//       state.pageData = pageData;
//       state.pageDataNumber = pageDataNumber;
//       state.pageDataLat = pageDataLat;
//       state.pageDataLong = pageDataLong;
//     },
//     clearPageData(state) {
//       state.pageData = null;
//       state.pageDataNumber = null;
//       state.pageDataLat = null;
//       state.pageDataLong = null;
//     },
//     setPageSearch(state, { q, prov_, city_, type_, cui_, menu, resto }) {
//       state.q = q;
//       state.prov_ = prov_;
//       state.city_ = city_;
//       state.type_ = type_;
//       state.cui_ = cui_;
//       state.menu = menu;
//       state.resto = resto;
//     },
//     clearPageSearch(state) {
//       state.pageSearchMenu = null;
//       state.pageSearchResto = null;
//     },
//     setPageResto(
//       state,
//       {
//         pageRestoCategoryOptions,
//         pageRestoBannerOptions,
//         pageRestoTransaction,
//         legitimateIosDevice,
//         permissionLocation,
//         latitude,
//         longitude,
//         device_id,
//       }
//     ) {
//       state.pageRestoCategoryOptions = pageRestoCategoryOptions;
//       state.pageRestoBannerOptions = pageRestoBannerOptions;
//       state.pageRestoTransaction = pageRestoTransaction;
//       state.legitimateIosDevice = legitimateIosDevice;
//       state.permissionLocation = permissionLocation;
//       state.latitude = latitude;
//       state.longitude = longitude;
//       state.device_id = device_id;
//     },
//     clearPageResto(state) {
//       state.pageRestoCategoryOptions = null;
//       state.pageRestoBannerOptions = null;
//       state.pageRestoTransaction = null;
//       state.legitimateIosDevice = null;
//       state.permissionLocation = null;
//       state.latitude = null;
//       state.longitude = null;
//       state.device_id = null;
//     },
//     setPageHistory(
//       state,
//       {
//         historyData,
//         // idHistoryData,
//         historyPreviousRouteData,
//       }
//     ) {
//       state.historyData = historyData;
//       // state.idHistoryData = idHistoryData;
//       state.historyPreviousRouteData = historyPreviousRouteData;
//     },
//     clearPageHistory(state) {
//       state.historyData = null;
//       // state.idHistoryData = null;
//       state.historyPreviousRouteData = null;
//     },
//   },
//   modules: {
//     voucherModule,
//     restoCreateModule,
//     themeModule,
//     cartModule,
//   },
// });
const store = createStore({
  state() {
    return {
      //RestoFeature
      pageData: null,
      pageDataNumber: null,
      pageDataLat: null,
      pageDataLong: null,
      //RestoSearch
      q: null,
      prov_: null,
      city_: null,
      type_: null,
      cui_: null,
      menu: null,
      resto: null,
      //Resto (User)
      pageRestoCategoryOptions: null,
      pageRestoBannerOptions: null,
      pageRestoTransaction: null,
      legitimateIosDevice: null,
      permissionLocation: null,
      latitude: null,
      longitude: null,
      device_id: null,
      //History (User)
      historyData: null,
      // idHistoryData: null,
      historyPreviousRouteData: null,
    };
  },
  mutations: {
    setPageData(
      state,
      { pageData, pageDataNumber, pageDataLat, pageDataLong }
    ) {
      state.pageData = pageData;
      state.pageDataNumber = pageDataNumber;
      state.pageDataLat = pageDataLat;
      state.pageDataLong = pageDataLong;
    },
    clearPageData(state) {
      state.pageData = null;
      state.pageDataNumber = null;
      state.pageDataLat = null;
      state.pageDataLong = null;
    },
    setPageSearch(state, { q, prov_, city_, type_, cui_, menu, resto }) {
      state.q = q;
      state.prov_ = prov_;
      state.city_ = city_;
      state.type_ = type_;
      state.cui_ = cui_;
      state.menu = menu;
      state.resto = resto;
    },
    clearPageSearch(state) {
      state.pageSearchMenu = null;
      state.pageSearchResto = null;
    },
    setPageResto(
      state,
      {
        pageRestoCategoryOptions,
        pageRestoBannerOptions,
        pageRestoTransaction,
        legitimateIosDevice,
        permissionLocation,
        latitude,
        longitude,
        device_id,
      }
    ) {
      state.pageRestoCategoryOptions = pageRestoCategoryOptions;
      state.pageRestoBannerOptions = pageRestoBannerOptions;
      state.pageRestoTransaction = pageRestoTransaction;
      state.legitimateIosDevice = legitimateIosDevice;
      state.permissionLocation = permissionLocation;
      state.latitude = latitude;
      state.longitude = longitude;
      state.device_id = device_id;
    },
    clearPageResto(state) {
      state.pageRestoCategoryOptions = null;
      state.pageRestoBannerOptions = null;
      state.pageRestoTransaction = null;
      state.legitimateIosDevice = null;
      state.permissionLocation = null;
      state.latitude = null;
      state.longitude = null;
      state.device_id = null;
    },
    setPageHistory(
      state,
      {
        historyData,
        // idHistoryData,
        historyPreviousRouteData,
      }
    ) {
      state.historyData = historyData;
      // state.idHistoryData = idHistoryData;
      state.historyPreviousRouteData = historyPreviousRouteData;
    },
    clearPageHistory(state) {
      state.historyData = null;
      // state.idHistoryData = null;
      state.historyPreviousRouteData = null;
    },
  },
  modules: {
    theme: themeModule,
    voucher: voucherModule,
    restoCreate: restoCreateModule,
    cart: cartModule,
  },
});

export default store;
