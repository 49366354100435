<template>
  <div id="app">
    <div class="main-wrapper bg-mooopo">
      <div class="main-content">
        <div class="col-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3 p-0">
          <div class="container container-welcome text-center">
            <h1 class="text-moopo font-weight-bold text-150rem">
              Sign Up Account
            </h1>
            <Form class="text-left" @submit="handleRegister" :validation-schema="schema" v-slot="{ errors }">
              <div class="form-group">
                <label for="username">Name</label>
                <Field id="username" name="username" type="text" class="form-control"
                  :class="{ 'is-invalid': errors.username }" />
                <ErrorMessage name="username" class="invalid-feedback" as="div" />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <Field id="email" name="email" type="email" class="form-control"
                  :class="{ 'is-invalid': errors.email }" />
                <ErrorMessage name="email" class="invalid-feedback" as="div" />
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <div class="input-group">
                  <Field id="password" name="password" :type="see_password == true ? 'password' : 'text'"
                    class="form-control" :class="{ 'is-invalid': errors.password }" />
                  <div class="input-group-append">
                    <div class="input-group-text" :class="{ 'border-danger': errors.password }">
                      <font-awesome-icon icon="fa-regular fa-eye-slash" @click="handlePassword()" />
                    </div>
                  </div>
                  <ErrorMessage name="password" class="invalid-feedback" as="div" />
                </div>
              </div>
              <div class="form-group">
                <label for="confpassword">Confirm Password</label>
                <div class="input-group">
                  <Field id="confpassword" name="confpassword"
                    :type="see_confirm_password == true ? 'password' : 'text'" class="form-control"
                    :class="{ 'is-invalid': errors.confpassword }" />
                  <div class="input-group-append">
                    <div class="input-group-text" :class="{ 'border-danger': errors.confpassword }">
                      <font-awesome-icon icon="fa-regular fa-eye-slash" @click="handleConfirmationPassword()" />
                    </div>
                  </div>
                  <ErrorMessage name="confpassword" class="invalid-feedback" as="div" />
                </div>
              </div>
              <button :disabled="loading" id="btn-register"
                class="btn btn-lg btn-moopo text-white btn-rounded w-100 mb-2 mt-3">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                Sign Up
              </button>
            </Form>
            <div class="text-center mt-1">
              have an account?
              <a href="/login" class="text-moopo">Log In</a>
            </div>
            <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Form, Field, ErrorMessage } from "vee-validate";
import { PROD_URL } from "@/utils/url";
import { ref, inject } from "vue";
import * as yup from "yup";
import axios from "axios";
import { useRouter } from "vue-router";

const loading = ref(false)
const route = useRouter()
const api_url = inject("mainUrl") + "/api/auth/register"
const message = ref("")
const see_password = ref(false)
const see_confirm_password = ref(false)

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Username harus diisi"),
  email: yup
    .string()
    .email("Email Tidak Valid")
    .required("Email harus diisi"),
  password: yup
    .string()
    .required("Password harus diisi"),
  confpassword: yup
    .string()
    .required("Confirm Password harus diisi"),
});

function handlePassword() {
  see_password.value = !see_password.value;
}

function handleConfirmationPassword() {
  see_confirm_password.value = !see_confirm_password.value;
}

function handleRegister(user, actions) {
  loading.value = true;

  if (user.password != user.confpassword) {
    actions.setFieldError("password", 'Password tidak sama dengan Confirm Password');
    loading.value = false;
  } else {
    let form = new FormData();
    form.append("name", user.username);
    form.append("email", user.email);
    form.append("password", user.password);
    if (localStorage.getItem("device_id") !== null) {
      form.append("device_id", localStorage.getItem("device_id"));
    }

    axios
      .post(`${PROD_URL}/api/auth/register`, form)
      .then((res) => {
        console.log(res);
        if (res["status"] == 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));

          route.push("/home");
        }
      })
      .catch((err) => {
        actions.setFieldError("email", err.response.data.errors.email);
        loading.value = false;
      });
  }
}
</script>

<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

.main-content {
  width: 100% !important;
}

.main-wrapper {
  background-color: #fff;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>@/services/form